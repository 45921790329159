/* Root Styling */
html,
body,
#root {
    height: 100vh;
    margin: 0;
    background-color: white;
    font-family: 'acumin-pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    line-height: 0;
}

/* App Styling */

.app-body {
    display: flex;
    justify-content: center;
    padding: 0 3.5rem 0 7.5rem;
    margin: 0 0;
    height: 100%;
}

.app-body-home-page {
    padding: 0 0 0 8rem;
}

.app-body-login-page {
    padding: 0;
    margin-left: 0;
}

/* Typography Styling */
.small-page-header-font {
    font-weight: 700;
    font-size: 24px;
}

.header-font {
    font-size: 22px;
}

.section-title-font {
    font-size: 16px;
}

.field-label-font {
    font-size: 14px;
}

/* Hide the attribution that is included with the free version. */
.react-flow__attribution {
    display: none;
}
