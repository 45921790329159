/* Pagination styling */
#contacts-table-pagination-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: 40px;
}

#contacts-table-rows-per-page {
    display: flex;
    flex-direction: row;
    align-items: center;
}

#contacts-table-rows-per-page-dropdown {
    margin-right: 10px;
}

.contacts-table-rows-per-page-dropdown-text {
    width: 30px;
    text-align: center;
}

#contacts-table-page-number {
    margin-right: 10px;
}

#contacts-table-page-navigation {
    height: 100%;
}

#contacts-table-page-navigation-button {
    color: var(--green);
    border-radius: 0;
    height: 100%;
}
